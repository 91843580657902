import { Button, Paper, Stack, Typography } from '@mui/material';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/hooks/useLocales';

type Props = {
  openDeleteDialog: boolean;
  handleCloseDeleteDialog: () => void;
  handleDelete: () => void;
  item?: string;
};

const DeleteConfirmationModal = ({
  openDeleteDialog,
  handleCloseDeleteDialog,
  handleDelete,
  item,
}: Props) => {
  const { translate } = useLocales();

  return (
    <DialogAnimate open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
      <Paper
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          {String(
            translate('global.deleteConfirmation.question', {
              item,
            })
          )}
        </Typography>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          spacing={2}
          justifyContent="center"
          sx={{ marginTop: 3 }}
        >
          <Button
            variant="contained"
            onClick={handleCloseDeleteDialog}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography variant="body2">
              {String(
                translate('global.deleteConfirmation.cancelDelete', {
                  item,
                })
              )}
            </Typography>
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDelete}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography variant="body2">
              {String(
                translate('global.deleteConfirmation.confirmDelete', {
                  item,
                })
              )}
            </Typography>
          </Button>
        </Stack>
      </Paper>
    </DialogAnimate>
  );
};

export default DeleteConfirmationModal;
