import { useDelete, useGet, usePost, usePut } from '#/api/reactQuery';
import queryClient from '#/api/query-client';
import { IBeneficiary } from '#/types/beneficiaries';

const baseURL = '/beneficiaries';

export const BeneficiariesEndpoints = {
  getBeneficiaries: () => `${baseURL}/list/`,
  getBeneficiary: (beneficiaryId: string | number | undefined) =>
    `${baseURL}/${beneficiaryId}/`,
  addBeneficiary: () => `${baseURL}/create/`,
  updateBeneficiary: (beneficiaryId: string | number | undefined) =>
    `${baseURL}/update/${beneficiaryId}/`,
  deleteBeneficiary: (beneficiaryId: string | number | undefined) =>
    `${baseURL}/delete/${beneficiaryId}/`,
};

export const invalidateBeneficiariesQueries = {
  getBeneficiaries: () => {
    queryClient
      .invalidateQueries(BeneficiariesEndpoints.getBeneficiaries())
      .then((r) => r);
  },
  getBeneficiary: (beneficiaryId: string | number | undefined) => {
    queryClient
      .invalidateQueries(BeneficiariesEndpoints.getBeneficiary(beneficiaryId))
      .then((r) => r);
  },
};

export const useGetBeneficiaries = () =>
  useGet<IBeneficiary[]>(BeneficiariesEndpoints.getBeneficiaries());

export const useGetBeneficiary = (
  beneficiaryId: string | number | undefined,
  enabled: boolean
) =>
  useGet<IBeneficiary>(
    BeneficiariesEndpoints.getBeneficiary(beneficiaryId),
    {},
    {
      enabled,
    }
  );

export const useAddBeneficiary = () =>
  usePost<IBeneficiary, IBeneficiary>(BeneficiariesEndpoints.addBeneficiary());

export const useUpdateBeneficiary = (
  beneficiaryId: string | number | undefined
) =>
  usePut<IBeneficiary, IBeneficiary>(
    BeneficiariesEndpoints.updateBeneficiary(beneficiaryId)
  );

export const useDeleteBeneficiary = (
  beneficiartId: string | number | undefined
) =>
  useDelete<IBeneficiary>(
    BeneficiariesEndpoints.deleteBeneficiary(beneficiartId)
  );
